<template>
  <div class="success">
    <p class="title" v-if="phoneType != -1">提交成功</p>
    <p class="success-top">
      <img src="./img/success.png" alt="" />
      <span>提交成功</span>
    </p>
    <p class="cont">
      {{ title }}
    </p>
    <p class="btn" @click="goto()">{{ btnTit }}</p>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import APP from '@/utils/App'
import { routerTag } from '../../utils/index'
export default {
  props: ['data'],
  setup (props) {
    const router = useRouter()
    const state = reactive({
      title: props.data.title,
      btnTit: props.data.btnTit,
      phoneType: null
    })
    const goto = () => {
      APP.Back(2)
    }
    onMounted(() => {
      //   APP.PAGE_WILL_LOAD(function(){
      //   window.location.reload();
      // })
      let phoneType
      phoneType = navigator.userAgent.indexOf('Android')
      console.log(phoneType, '机型')
      state.phoneType = phoneType
      if (phoneType == -1) {
        APP.SET_TITLE('提交成功')
        APP.HIDE_BACK(true)
      } else {
        APP.SET_HEADER(false)
        // document.title('申请成功')
      }
    })
    return {
      ...toRefs(state),
      goto
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
